import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_5";
import BlockSecondary from "../components/block/Block_6";
import BlockAbout from "../components/block/Block_2";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import Form from "../components/Contact/Form";
import { ButtonContent } from "../components/global/boton/ButtonContent";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection />


        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image={rpdata?.stock?.[0]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        <Directories />

        <div className='bgCountent' style={{ backgroundImage: `url("${rpdata?.stock?.[6]}")` }}>
          <div className="relative">
            <div className="flex flex-col justify-center items-center">
              <img
                src={`${rpdata?.dbPrincipal?.logo}`}
                alt='no found'
                className='w-[50%] md:w-[15%]'
              />
              <h2 className='text-center px-5 md:px-[20%] py-4 relative text-white'>{rpdata?.dbSlogan?.[1].slogan}</h2>
              <ButtonContent />
            </div>
          </div>
        </div>

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.gallery?.[13]}
          image2={rpdata?.gallery?.[14]}
          image3={rpdata?.gallery?.[5]}
          image4={rpdata?.gallery?.[15]}
        />

        <div className="bgBloqueGradiente py-10 mt-5"></div>


        <CounterUp image={rpdata?.gallery?.[14]} />

        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[8]}
          listsAbout={true}
        />


        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.gallery} />
            </div>
            :
            null

        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }

        <div className="bgBloqueGradiente py-20"></div>
        <div className="flex flex-col md:flex-row pb-10">
          <div
            className="bg-cover bg-no-repeat bg-center w-full md:w-[50%] h-[100vh] flex justify-center items-center"
            style={{ backgroundImage: `url("${rpdata?.stock?.[9]}")` }}
          >
            <img
              src={`${rpdata?.dbPrincipal?.logo}`}
              alt='no found'
              className='w-[70%] mx-auto'
            />
          </div>
          <div className='max-w-2xl p-5 mt-20 mx-5 bg-white shadow-lg flex flex-col justify-center '>
            <h2 className='text-center'>Send Us A Message</h2>
            <div className='p-4'>
              <Form />
            </div>
          </div>
        </div>


        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
